import imageUrlBuilder from '@sanity/image-url';

import app from './app';

const sanity = {
  projectId: app.sanityProjectId,
  dataset: app.sanityDataset,
};

const buildImageObj = (
  source = {
    asset: {} as any,
  },
): any => {
  return {
    ...source,
    asset: { ...source.asset, _ref: source.asset._ref || source.asset._id },
  };
};

const builder = imageUrlBuilder(sanity);

const imageUrlFor = (source: any): any => {
  return builder.image(buildImageObj(source));
};

export default imageUrlFor;
