import { FC } from 'react';

import { Link } from 'gatsby';

type Props = { showLink?: boolean };

const Header: FC<Props> = ({ showLink = true }) => (
  <div className='header'>
    {showLink && (
      <p className='trade-registry'>
        <Link to='/'>Alpaca Guild</Link>
      </p>
    )}
  </div>
);

export default Header;
