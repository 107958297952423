import { FC } from 'react';

import BasePortableText from '@sanity/block-content-to-react';

import serializers from 'helpers/serializers';
import app from 'helpers/app';

interface Prop {
  blocks: { _type: string; _key: string; style: string; children: []; markDefs: [] };
}

const sanity = {
  projectId: app.sanityProjectId,
  dataset: app.sanityDataset,
};

const PortableText: FC<Prop> = ({ blocks }) => (
  <BasePortableText blocks={blocks} serializers={serializers} {...sanity} />
);

export default PortableText;
