import { GatsbyImage } from 'gatsby-plugin-image';

import { GatsbyImageDataArgs, getGatsbyImageData } from 'gatsby-source-sanity';

import app from 'helpers/app';

const sanity = {
  projectId: app.sanityProjectId,
  dataset: app.sanityDataset,
};

const Figure = ({ node }: any): any => {
  if (!node?.asset?._id) {
    return null;
  }
  const gatsbyImageData = getGatsbyImageData(
    node,
    { maxWidth: 675 } as GatsbyImageDataArgs,
    sanity,
  );

  if (!gatsbyImageData) {
    throw new Error('There is no image with such a name');
  }

  return (
    <figure>
      <GatsbyImage image={gatsbyImageData} alt={node.alt} />
      <figcaption>{node.caption}</figcaption>
    </figure>
  );
};

export default Figure;
