import { FC } from 'react';

interface Props {
  small?: boolean;
}

const ImagePlaceholder: FC<Props> = ({ small }) => (
  <div className={`image-placeholder ${small ? 'small' : 'big'}`} />
);

export default ImagePlaceholder;
